import React from "react";
import {Routes, Route} from 'react-router-dom'

import HomePage from "./components/HomePage";
import PostsPage from "./components/PostsPage";
import Feedback from "./components/Feedback";
import SuccessPage from "./components/SuccessPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import UserAccountDelete from "./components/UserAccountDelete";


function App() {
  

  return (
    <div >
      <Routes>
        <Route path='/' element={<HomePage />}></Route>
        <Route path='/PostsPage' element={<PostsPage />}></Route>
        <Route path='/Feedback' element={<Feedback />}></Route>
        <Route path='/SuccessPage' element={<SuccessPage />}></Route>
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />}></Route>
        <Route path='/UserAccountDelete' element={<UserAccountDelete />}></Route>
        
      </Routes>
    </div>

  );
}

export default App;