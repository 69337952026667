import React, { useState } from "react";
import "../css/DeleteAccount.css";

import axiosBaseURL from '../httpCommon.js';

const UserAccountDelete = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [jwtToken, setJwtToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage("");
    try {
      
      const response = await axiosBaseURL.post(`/users/authenticate`, { username, password });
      
      if (response.data.statusCode === 200 ) {
        setJwtToken(response.data.data.token);
        setUserId(response.data.data.id);
        setShowConfirm(true);
      } else {
        setMessage("Invalid user details");
      }
    } catch (error) {
      setMessage("Error logging in. Try again later.");
    }
    
  };

   
  const handleDelete = async () => {
    setShowConfirm(false);
    try {
     
      const response = await axiosBaseURL.delete(`/users/${userId}`, { headers: { "Authorization": `Bearer ${jwtToken}` } });
      
      if (response.data.statusCode === 204) {
        setMessage("Account successfully deleted.");
      } else {
        setMessage("Failed to delete account.");
      }
    } catch (error) {
      setMessage("Error deleting account. Try again later.");
    }
  };

  return (
    <>
     {/* ==== header start ==== */}
     <header class="header" style={{ position: `sticky`, top: `0` , marginTop: `20px`}}>
        <div class="container" >
          <div class="row" >
            <div class="col-lg-12">
              <nav class="nav" >
                <div class="nav__content" >
                  <div class="nav__logo">
                    <a href="/">
                      <img src={require('../images/logo.svg').default} alt="" />
                    </a>

                  </div>
                  <div class="nav__menu">
                    <div class="nav__menu-logo d-flex d-xl-none">
                      <a href="/" class="text-center hide-nav">

                        <img src={require('../images/logo.svg').default} alt="" />
                      </a>
                      <a href="/" class="nav__menu-close">
                        <i class="fa-solid fa-xmark"></i>
                      </a>
                    </div>
                    <ul class="nav__menu-items">
                      
                      
                    </ul>

                  </div>
                  <div class="nav__uncollapsed">
                    <div class="nav__uncollapsed-item d-none d-md-flex">
                      
                    </div>
                    <button class="nav__bar d-block d-xl-none">
                      <span class="icon-bar top-bar"></span>
                      <span class="icon-bar middle-bar"></span>
                      <span class="icon-bar bottom-bar"></span>
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="backdrop"></div>
      </header>

<div className="del-container">
      <h4>Delete Account</h4>
      <form onSubmit={handleLogin}>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <button type="submit">Submit</button>
      </form>
      

      {message && <p>{message}</p>}

      {showConfirm && (
        <div className="modal">
          <div className="modal-content">
          <p>Are you sure you want to delete your account?</p>
          <button onClick={handleDelete}>Yes, Delete</button>
          <button onClick={() => setShowConfirm(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
    </>
    
  );
};

export default UserAccountDelete;